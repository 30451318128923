import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import locationCondition from './locationCondition';
import name from './name';
import organization from './organization';
import searchPartner from './searchPartner';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          // 'tagNodes.tagId': {
          //   $in: values
          // },
          id: {
            $includes_any_tag_in: {
              'tag.id': values
            }
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  activities: tag(),
  ages: tag(),
  experiences: tag(),
  expertiseTypes: tagTree(),
  groups: tag(),
  guidances: tag(),
  locationCondition,
  name,
  organization,
  searchPartner
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
