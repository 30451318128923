import React from 'react';
import { useConnect } from '@lore/query-connect';
import Entry from '../../../user/User/Entry';
import classNames from 'classnames';
import getUrlForUpload from '../../../user/User/getUrlForUpload';
// import ImageChildBuilder from '../../../evaluations/_common/ImageChildBuilder';
import Images from '../../../story/Story/Images';
import EmptyState from '../../../../components/EmptyState';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Section from '../../../../components_profiles/Section';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../../components/Loader';
import getUploadInfo from '../../../evaluation-project-educator/Uploads/getUploadInfo';

export default function Photographs(props) {
  const { project } = props;

  const title = `Explore project highlights in photos`;
  const subtitle = `Explore moments from the project such as activities and examples of student learning.`;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          'evaluationProjectEducator.projectId': project.id
        }
      }
    }
  });

  if (evaluationUploads.state === PayloadStates.FETCHING) {
    return (
      <Section
        title={title}
        subtitle={subtitle}
      >
        <Loader className="mx-7" />
      </Section>
    );
  }

  const _images = evaluationUploads.data.filter(function(upload) {
    const info = getUploadInfo(upload.data.name);
    return info.isImage;
  });

  if (_images.length === 0) {
    return null;
  }

  return (
    <Section
      title={title}
      subtitle={subtitle}
    >
      <div className="space-y-4">
        <Images
          className={classNames(
            'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4'
          )}
          imageHeight="h-64 sm:h-64 lg:h-64"
          images={{
            data: _images.map(function(upload) {
              const url = getUrlForUpload(upload);

              return {
                id: upload.id,
                data: {
                  name: upload.data.name,
                  imageUrl: url,
                  // width: 0,
                  // height: 0
                },
                _upload: upload
              };
            })
          }}
        />
      </div>
    </Section>
  );
}
