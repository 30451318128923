import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import search from './search';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          // 'tagNodes.tagId': {
          //   $in: values
          // },
          id: {
            $includes_any_tag_in: {
              'tag.id': values
            }
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  activities: tag(),
  ages: tag(),
  grades: tag(),
  interactionTypes: tag(),
  subjects: tag(),
  search
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
