import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import Date from '../../projects/Project/Date';
import Images from '../../projects-past/Project/Images';
import Testimonials from '../../projects-past/Project/Testimonials';
import School from '../../projects/Project/School';
import getUploadInfo from '../../evaluation-project-educator/Uploads/getUploadInfo';
import getUrlForUpload from '../../user/User/getUrlForUpload';

Header.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Header(props) {
  const { project } = props;

  const upload = useConnect('upload.first', {
    where: {
      eager: {
        $where: {
          'evaluationProjectEducator.projectId': project.id
        }
      }
    }
  });

  const info = getUploadInfo(upload.data.name);
  const url = getUrlForUpload(upload);

  return (
    <div className="relative">
      <div className="h-48 rounded-cs-10 overflow-hidden w-full relative bg-cs-gray-500  mb-4">
        {/*<div className="absolute z-10 top-2 right-4">*/}
        {/*  <ProjectBookmark*/}
        {/*    project={project}*/}
        {/*    user={currentUser}*/}
        {/*  />*/}
        {/*</div>*/}
        {info.isImage && (
          <img
            className="absolute left-0 top-0 object-cover object-center h-full w-full"
            src={url}
            alt="image"
          />
        )}
      </div>
      <div className="text-lg text-cs-gray-900 font-semibold mb-2x">
        {project.data.title}
      </div>
      {/*<div className="flex flex-wrap gap-x-4 gap-y-2">*/}
      {/*  <Date project={project} />*/}
      {/*  <School project={project} />*/}
      {/*  <Images project={project} />*/}
      {/*  <Testimonials project={project} />*/}
      {/*</div>*/}
    </div>
  );
};
