import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function (text) {
    // const words = text.trim().split(' ');
    const words = text.trim();

    if (words) {
      return {
        'site.school.agencyName': {
          $ilike: `%${words}%`
        }
      };

      // return {
      //   $or: words.map(function(word){
      //     return {
      //       'site.school.agencyName': {
      //         $ilike: `%${word}%`
      //       }
      //     };
      //   })
      // };
    }
  }
}
