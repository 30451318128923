import getQueryUtils, { attributeKeys } from './queryUtils';
import REQUIRED_FIELDS_PUBLIC from './REQUIRED_FIELDS_PUBLIC';
import REQUIRED_FIELDS_PAST from './REQUIRED_FIELDS_PAST';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 4,
    showPast = false,
    distance = {}
  } = props;

  const queryUtils = getQueryUtils(search, attributeKeys);

  return useConnect('project.find', {
    where: {
      distance: _.pick(distance, ['latitude', 'longitude']),
      eager: {
        $where: {
          $and: [
            ...queryUtils.where,
            // ...REQUIRED_FIELDS_PUBLIC.$and
            ...(showPast ? REQUIRED_FIELDS_PAST : REQUIRED_FIELDS_PUBLIC).$and
          ]
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
