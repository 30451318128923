import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../../../components/Card';
import Tooltip from '../../_post-opportunity/forms/Tooltip';
// import Tooltip from '../../_components/Tooltip';

Intent.propTypes = {
  title: PropTypes.node.isRequired,
  avatar: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Intent(props) {
  const {
    title,
    avatar,
    description,
    guidance,
    selected,
    onClick
  } = props;

  return (
    <Card
      className={classNames(
        'p-6 cursor-pointer hover:bg-cs-orange-50',
        selected ? 'border-cs-orange-500 border-2 bg-cs-orange-50' : 'border border-cs-gray-300 m-px',
        'flex flex-col sm:flex-row items-start sm:items-center gap-4'
      )}
      onClick={onClick}
    >
      <img src={avatar} className="w-20 h-20 rounded-full object-center object-cover bg-cs-orange-50 inline-block"/>
      <div>
        <div className="flex items-center space-x-1 mb-1">
          <div className="text-xl text-cs-gray-800 font-semibold">
            {title}
          </div>
          {guidance && (
            <Tooltip tooltip={guidance} />
          )}
        </div>
        <div className="text-base text-cs-gray-800">
          {description}
        </div>
      </div>
    </Card>
  );
};
