import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import locationCondition from '../../people-partners/queryUtils/locationCondition';
import name from '../../people-partners/queryUtils/name';
import organization from '../../people-partners/queryUtils/organization';
import searchEducator from './searchEducator';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          // 'tagNodes.tagId': {
          //   $in: values
          // },
          id: {
            $includes_any_tag_in: {
              'tag.id': values
            }
          }
        }
      }
    }
  };
}

const attributes = {
  grades: tag(),
  locationCondition,
  name,
  organization,
  practices: tag(),
  searchEducator,
  subjects: tag()
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
