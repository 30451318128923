import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import SectionHeader from '../../../components_profiles/SectionHeader';

Summary.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Summary(props) {
  const { project } = props;

  if (!project.data.desiredOutcome) {
    return null;
  }

  return (
    <div className="space-y-2">
      <SectionHeader
        title="The original idea (desired outcome)"
      />
      <div className="text-base text-cs-gray-500">
        <ClampableText markup={true}>
          {project.data.desiredOutcome || '[No outcome provided]'}
        </ClampableText>
      </div>
    </div>
  );
};
