import React from 'react';
import { useConnect } from '@lore/query-connect';
import Entry from '../../../user/User/Entry';
import classNames from 'classnames';
import getUrlForUpload from '../../../user/User/getUrlForUpload';
// import ImageChildBuilder from '../../../evaluations/_common/ImageChildBuilder';
import Images from '../../../story/Story/Images';
import EmptyState from '../../../../components/EmptyState';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Section from '../../../../components_profiles/Section';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../../components/Loader';
import Resource from '../../../../components_profiles/Resource';
import getUploadInfo from '../../../evaluation-project-educator/Uploads/getUploadInfo';

export default function Uploads(props) {
  const { project } = props;

  const title = `Check out these resources for a greater look into the project`;
  const subtitle = `Explore moments from the project such as activities and examples of student learning.`;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          'evaluationProjectEducator.projectId': project.id
        }
      }
    }
  });

  if (evaluationUploads.state === PayloadStates.FETCHING) {
    return (
      <Section
        title={title}
        subtitle={subtitle}
      >
        <Loader className="mx-7" />
      </Section>
    );
  }

  const _documents = evaluationUploads.data.filter(function(upload) {
    const info = getUploadInfo(upload.data.name);
    return !info.isImage;
  });

  if (_documents.length === 0) {
    return null;
  }

  return (
    <Section
      title={title}
      subtitle={subtitle}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2x gap-6">
        {_documents.map(function(upload) {
          return (
            <Resource
              key={upload.id}
              title={upload.data.name}
              description={upload.data.description}
              url={upload.data.url}
            />
          );
        })}
      </div>
    </Section>
  );
}
